<script setup>
import { TETextInput, TEButton, TETopLabelLayout, TETextButton } from '@ben/vite-te'
import { Head, useForm } from '@inertiajs/vue3'

defineProps({
  canResetPassword: Boolean,
  status: {
    type: String,
    default: '',
  },
})

const form = useForm({
  email: '',
  password: '',
  remember: false,
})

const submit = () => {
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? 'on' : '',
    }))
    .post(route('login'), {
      onFinish: () => form.reset('password'),
    })
}
</script>

<template>
  <CenterLayout>
    <Head title="Log in" />

    <AuthenticationCard>
      <template #logo>
        <AuthenticationCardLogo />
      </template>

      <div v-if="status" class="mb-4 text-sm font-medium text-green-600 dark:text-green-400">
        {{ status }}
      </div>

      <form @submit.prevent="submit">
        <div>
          <TETopLabelLayout name="email" title="Email" :error="form.errors.email">
            <TETextInput
              v-model="form.email"
              name="email"
              type="email"
              required
              autofocus
              autocomplete="username"
            />
          </TETopLabelLayout>
        </div>

        <div class="mt-4">
          <TETopLabelLayout name="password" title="Password" :error="form.errors.password">
            <TETextInput
              v-model="form.password"
              name="password"
              type="password"
              required
              autocomplete="current-password"
            />
          </TETopLabelLayout>
        </div>

        <div class="mt-4 block">
          <label class="flex items-center">
            <Checkbox v-model:checked="form.remember" name="remember" />

            <span class="ms-2 text-sm text-gray-600 dark:text-gray-400">Remember me</span>
          </label>
        </div>

        <div class="mt-4 flex items-center justify-end">
          <TETextButton v-if="canResetPassword" tag="link" :href="route('password.request')">
            Forgot your password?
          </TETextButton>

          <TEButton type="submit" class="ms-4" :disabled="form.processing"> Log in </TEButton>
        </div>
      </form>
    </AuthenticationCard>
  </CenterLayout>
</template>
